
@font-face {
  font-family: 'Diodrum-Regular';
  font-style: normal;
  font-weight: 400;
  src: local('Diodrum-Regular'),
    url('fonts/Diodrum-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Diodrum-Semibold';
  font-style: normal;
  font-weight: 600;
  src: local('Diodrum-Semibold'),
    url('fonts/Diodrum-Semibold.otf') format('opentype');
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html {
  zoom:90%;
}

body { 
  font-family: 'Diodrum-Regular';
  letter-spacing: 0.7px;
  overflow: hidden;
  color: #313131 !important;
} 